import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AuthPanelComponent } from '../auth-panel/auth-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';
import { AuthContextSelectorComponent } from '@ciphr/core/auth-context/features';
import { Store } from '@ngrx/store';
import { uiActions } from '../state/ui/ui.actions';

@Component({
  selector: 'mypay-reporting-header',
  standalone: true,
  imports: [RouterLink, AuthPanelComponent, MatIconModule, MatButtonModule, AsyncPipe, AuthContextSelectorComponent],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(private store: Store) {}

  openSidenav(): void {
    this.store.dispatch(uiActions.toggleSidenav(true));
  }
}
