import { AsyncPipe } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterLinkActive, RouterLink, RouterOutlet } from '@angular/router';

import { filter, map, shareReplay, tap, withLatestFrom } from 'rxjs';

import { Store } from '@ngrx/store';

import { AccessManagementDirective } from '@ciphr/shared/access-management/features';
import { selectAccessItems } from '@ciphr/shared/access-management/state';

import { AuthPanelComponent } from '../auth-panel/auth-panel.component';
import { HeaderComponent } from '../header/header.component';
import { selectSideNavOpened } from '../state/ui/ui.selectors';
import { uiActions } from '../state/ui/ui.actions';

@Component({
  selector: 'mypay-reporting-navigation',
  standalone: true,
  imports: [
    MatSidenavModule,
    RouterOutlet,
    MatListModule,
    RouterLink,
    AsyncPipe,
    MatButtonModule,
    RouterLinkActive,
    HeaderComponent,
    AuthPanelComponent,
    MatIconModule,
    AccessManagementDirective,
  ],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  accessItems$ = this.store.select(selectAccessItems).pipe(tap(() => this.router.navigate(['/'])));
  desktopView$ = this.breakpointObserver.observe([Breakpoints.Web]).pipe(
    tap(({ matches }) => this.store.dispatch(uiActions.toggleSidenav(matches))),
    map(({ matches }) => matches),
    shareReplay(),
  );
  mode$ = this.desktopView$.pipe(map((matches) => (matches ? 'side' : 'over')));
  opened$ = this.store.select(selectSideNavOpened);
  constructor(
    private router: Router,
    private store: Store,
    private breakpointObserver: BreakpointObserver,
  ) {
    router.events
      .pipe(
        withLatestFrom(this.desktopView$),
        filter(([event, isDesktopView]) => event instanceof NavigationEnd && !isDesktopView),
      )
      .subscribe(() => this.closeNav());
  }

  closeNav(): void {
    this.store.dispatch(uiActions.toggleSidenav(false));
  }
}
