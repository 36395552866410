import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { AuthService } from '@ciphr/core/auth';
import { Store } from '@ngrx/store';
import { uiActions } from '../state/ui/ui.actions';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'mypay-reporting-auth-panel',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatMenuModule],
  templateUrl: './auth-panel.component.html',
  styleUrls: ['./auth-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthPanelComponent {
  @Input() version: 'mobile' | 'desktop' = 'desktop';
  private readonly store = inject(Store);
  private readonly authService = inject(AuthService);

  get userName(): string | null {
    return this.authService.userName;
  }

  get firstNameWithLastName(): string {
    return this.authService.firstNameWithLastName;
  }

  get initials(): string {
    const names = this.authService.firstNameWithLastName?.split(' ');
    const firstName = names[0].charAt(0);
    const lastName = names.length > 1 ? names[names.length - 1].charAt(0) : '';
    const initials = lastName ? firstName + lastName : firstName;

    return initials.toUpperCase();
  }
  logout(): void {
    this.store.dispatch(uiActions.hideContentAfterLogout());
  }
}
